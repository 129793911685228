import schemaCity from '_schema/shippingCountry'
import schemaPayment from '_schema/paymentType'
import schemaProduct from '_schema/product'

const schema = {
  endpoint: '/api/orders',
  resourceForShow: {
    definition: 'Order-order.read',
    properties: {
      orderNumber: {
        //numer zamówienia unikatowy
        readonly: true,
      },
      iksorisNumber: {
        readonly: true,
        label: 'Numer zamówienia w iksoris, jesli były kupione bilety',
      },
      floatPrice: {
        //łączna cena z przesyłką i rabatami
        readonly: true,
        type: 'number',
        minValue: 0,
        endAdornment: 'PLN',
      },

      totalWeight: { readonly: true, endAdornment: 'g' },
      paymentTitle: { readonly: true },
      //payu
      isPayu: { readonly: true },
      payuStatus: { readonly: true },
      payuOrderId: { readonly: true },
      payuLink: { readonly: true },

      //przelew tradycyjny
      isTransfer: { readonly: true },

      discountCode: { readonly: true }, //uzyty kod promocujny
      floatDiscountCodeRate: {
        readonly: true,
        type: 'number',
        endAdornment: '%',
      }, //zniżka kodu %

      lang: {
        label: 'Język w którym użytkownik zakupił produkt',
        readonly: true,
      },

      userType: {
        type: 'choice',
        choices: {
          1: 'Osoba',
          2: 'Firma',
        },
        label: 'Kupił jako',
        readonly: true,
        fullWidth: false,
        validate: ['required'],
      },

      email: {
        //email zamawiającego
        readonly: true,
      },
      name: {
        //imię kupującego
        readonly: true,
      },
      surname: {
        readonly: true,
      },
      phone: {
        // disabled: true,
      },
      firm: {
        label: 'Nazwa firmy',
        readonly: true,
      },
      nip: {
        label: 'NIP',
        readonly: true,
      },
      street: {
        label: 'Ulica',
        readonly: true,
      },
      city: {
        label: 'Miasto',
        readonly: true,
      },
      zipcode: {
        label: 'Kod pocztowy',
        readonly: true,
      },
      country: {
        label: 'Państwo',
        readonly: true,
        type: 'resourceOrderCountry',
        endpoint: `${schemaCity.endpoint}?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        // storeCollectionId: schemaCity.extraStoreCollectionId,
      },

      isOtherAddress: {
        label: 'Inny adres dostawy',
        readonly: true,
      },

      nameOa: {
        //imię kupującego
        readonly: true,
      },
      surnameOa: {
        readonly: true,
      },
      phoneOa: {
        readonly: true,
      },
      streetOa: {
        label: 'Ulica',
        readonly: true,
      },
      cityOa: {
        label: 'Miasto',
        readonly: true,
      },
      zipcodeOa: {
        label: 'Kod pocztowy',
        readonly: true,
      },
      countryOa: {
        label: 'Państwo',
        readonly: true,
        type: 'resourceOrderCountry',
        endpoint: `${schemaCity.endpoint}?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        // storeCollectionId: schemaCity.extraStoreCollectionId,
      },

      isFv: {
        readonly: true,
        label: 'Zamawiający chce fakturę',
      },
      nipFv: { readonly: true, label: 'NIP' },
      firmFv: { readonly: true, label: 'Nazwa firmy' },
      nameFv: { readonly: true },
      surnameFv: { readonly: true },
      streetFv: {
        label: 'Ulica',
        readonly: true,
      },
      cityFv: {
        label: 'Miasto',
        readonly: true,
      },
      zipcodeFv: {
        label: 'Kod pocztowy',
        readonly: true,
      },
      countryFv: {
        label: 'Państwo',
        readonly: true,
        type: 'resourceOrderCountry',
        endpoint: `${schemaCity.endpoint}?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        // storeCollectionId: schemaCity.extraStoreCollectionId,
      },
      fvNotice: {
        label: 'Dodatkowe dane do faktury',
        type: 'textarea',
      },
      fvNumber: {
        label: 'Numer faktury',
        hint: 'można uzupełnić po wystawieniu',
      },

      userNotice: {
        //info od usera
        type: 'textarea',
      },

      parts: {
        type: 'embeddedcollection',
        endpoint: '/api/order_parts',
        definition: 'OrderPart-orderPart.read',
        description: 'Order parts',
        additionalProperties: {
          $ref: '#/definitions/OrderPart-orderPart.write_orderPart.create',
        },
        titleAccessor: `shippingName`,
        statable: false,
        disabled: false,
        showDelete: false,
        showAdder: false,
        showExpander: false,
        headerTitle: 'Zakupione produkty',
        showSubmit: false,

        properties: {
          orderStat: { type: 'orderStatus' }, //status przesyłki
          weight: { readonly: true, endAdornment: 'g' }, //waga przesyłki
          floatShippingCost: {
            //koszt przesyłki
            readonly: true,
            type: 'number',
            minValue: 0,
            endAdornment: 'PLN',
          },
          shippingAddress: {
            readonly: true,
            type: 'textarea',
            hint:
              'adres odbioru osobistego lub adres dostawy kurierem lub adres paczkoamtu',
          }, //adres odbioru osobistego lub adres dostawy kurierem lub adres paczkoamtu
          isPersonal: { readonly: true }, //odbiór osobisty
          shippingProvider: { readonly: true }, //np inpost
          inpostParcel: { readonly: true }, //jak inpost, top pokazuje sie paczkomat, numer
          freeDeliveryPrice:{ readonly: true }, //czy załapał darmową dostawę
          shippingName: { readonly: true },

          items: {
            type: 'tablecollection',
            endpoint: '/api/order_items',
            definition: 'OrderItem-orderItem.read',
            description: 'Order items',
            additionalProperties: {
              $ref: '#/definitions/OrderItem-orderItem.write_orderItem.create',
            },
            titleAccessor: `title`,
            statable: false,
            showSubmit: false,

            properties: {
              title: {}, //tytuł produktu
              price: {}, //price original
              priceRate: {}, //użyta zniżka na cenie
              discountRate: {}, //zniżka promocji
              discountPrice: {}, //cena zakupu
              quantity: {},
              isDigital: {}, //ebbok
              weight: { disabled: true }, //waga produktu
            },
          },
        },
      },
    },
  },
  resource: {
    definition: 'Order-order.read',
    properties: {
      orderNumber: {
        //numer zamówienia unikatowy
        readonly: true,
      },
      iksorisNumber: {
        readonly: true,
        label: 'Numer zamówienia w iksoris, jesli były kupione bilety',
      },
      floatPrice: {
        //łączna cena z przesyłką i rabatami
        type: 'number',
        minValue: 0,
        endAdornment: 'PLN',
      },

      totalWeight: { endAdornment: 'g' },

      lang: {
        label: 'Język w którym użytkownik zakupił produkt',
        readonly: true,
      },
      paymentTitle: {},

      payment: {
        label: 'Metoda płatności',
        readonly: true,
        type: 'resource',
        endpoint: `${schemaPayment.endpoint}?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        // storeCollectionId: schemaCity.extraStoreCollectionId,
      },

      //payu
      isPayu: { readonly: true },
      payuStatus: { readonly: true },
      payuOrderId: { readonly: true },
      payuLink: { readonly: true },

      //przelew tradycyjny, trzeba usunąć payu, payu link, payustatus
      isTransfer: { readonly: true },

      discountCode: { readonly: true }, //uzyty kod promocyjny
      floatDiscountCodeRate: {
        readonly: true,
        type: 'number',
        endAdornment: '%',
      }, //zniżka kodu %

      userType: {
        type: 'choice',
        choices: {
          1: 'Osoba',
          2: 'Firma',
        },
        label: 'Kupił jako',
        fullWidth: false,
        validate: ['required'],
      },

      email: {
        //email zamawiającego
      },
      name: {
        //imię kupującego
      },
      surname: {},
      phone: {},
      firm: {
        label: 'Nazwa firmy',
      },
      nip: {
        label: 'NIP',
      },
      street: {
        label: 'Ulica',
      },
      city: {
        label: 'Miasto',
      },
      zipcode: {
        label: 'Kod pocztowy',
      },
      country: {
        label: 'Państwo',
        type: 'resourceOrderCountry',
        endpoint: `${schemaCity.endpoint}?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        // storeCollectionId: schemaCity.extraStoreCollectionId,
      },

      isOtherAddress: {
        label: 'Inny adres dostawy',
      },

      nameOa: {
        //imię kupującego
      },
      surnameOa: {},
      phoneOa: {},
      streetOa: {
        label: 'Ulica',
      },
      cityOa: {
        label: 'Miasto',
      },
      zipcodeOa: {
        label: 'Kod pocztowy',
      },
      countryOa: {
        label: 'Państwo',

        type: 'resourceOrderCountry',
        endpoint: `${schemaCity.endpoint}?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        // storeCollectionId: schemaCity.extraStoreCollectionId,
      },

      isFv: {
        label: 'Zamawiający chce fakturę',
      },
      nipFv: { readonly: true, label: 'NIP' },
      firmFv: { readonly: true, label: 'Nazwa firmy' },
      nameFv: {},
      surnameFv: {},
      streetFv: {
        label: 'Ulica',
        readonly: true,
      },
      cityFv: {
        label: 'Miasto',
        readonly: true,
      },
      zipcodeFv: {
        label: 'Kod pocztowy',
        readonly: true,
      },
      countryFv: {
        label: 'Państwo',
        readonly: true,
        type: 'resourceOrderCountry',
        endpoint: `${schemaCity.endpoint}?pagination=false`,
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
        // storeCollectionId: schemaCity.extraStoreCollectionId,
      },
      fvNotice: {
        label: 'Dodatkowe dane do faktury',
        type: 'textarea',
      },
      fvNumber: {
        label: 'Numer faktury',
        hint: 'można uzupełnić po wystawieniu',
      },

      userNotice: {
        //info od usera
        type: 'textarea',
      },

      parts: {
        type: 'embeddedcollection',
        endpoint: '/api/order_parts',
        definition: 'OrderPart-orderPart.read',
        description: 'Order parts',
        additionalProperties: {
          $ref: '#/definitions/OrderPart-orderPart.write_orderPart.create',
        },
        titleAccessor: `shippingName`,
        statable: false,
        disabled: false,
        showDelete: false,
        showAdder: false,
        showExpander: false,
        headerTitle: 'Zakupione produkty',

        properties: {
          orderStat: { type: 'orderStatus' }, //status przesyłki
          weight: { endAdornment: 'g' }, //waga przesyłki
          floatShippingCost: {
            //koszt przesyłki
            type: 'number',
            minValue: 0,
            endAdornment: 'PLN',
          },
          shippingAddress: {
            type: 'textarea',
            hint:
              'adres odbioru osobistego lub adres dostawy kurierem lub adres paczkoamtu',
          }, //adres odbioru osobistego lub adres dostawy kurierem lub adres paczkoamtu
          isPersonal: {}, //odbiór osobisty
          shippingProvider: { readonly: true }, //np inpost
          inpostParcel: {}, //jak inpost, top pokazuje sie paczkomat, numer
          freeDeliveryPrice:{}, //czy załapał darmową dostawę
          shippingName: {},

          items: {
            type: 'embeddedcollection',
            endpoint: '/api/order_items',
            definition: 'OrderItem-orderItem.read',
            description: 'Order items',
            additionalProperties: {
              $ref: '#/definitions/OrderItem-orderItem.write_orderItem.create',
            },
            titleAccessor: `title`,
            statable: false,
            showSubmit: true,

            properties: {
              title: {
                hint: 'należy uzupełnić zgodnie z jezykiem klienta',
              }, //tytuł produktu
              // price: {}, //price original
              // priceRate: {}, //użyta zniżka na cenie
              // discountRate: {}, //zniżka promocji
              floatDiscountPrice: {
                label: 'Cena produktu',
                hint: 'dla jednej sztuki',
                type: 'number',
                minValue: 0,
              }, //cena zakupu
              quantity: {
                label: 'Zakupiona ilość',
              },
              isDigital: {
                label: 'Ebook',
              },
              weight: {
                label: 'Waga produktu',
                endAdornment: 'g',
              },
              product: {
                label: 'Powiązany produkt',
                readonly: true,
                type: 'resource',
                endpoint: `${schemaProduct.endpoint}?pagination=false`,
                titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`, //'uuid',
              },
            },
          },
        },
      },
    },
  },
}

export default schema
