import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { CollectionTable as CollectionTableTree } from './table/CollectionTable'
import { operations } from './table/columns/operations'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
import schema from 'core/_schema/project'
import routes from './routes'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import HomeIcon from '@material-ui/icons/Home'
import Link from '@material-ui/core/Link'
import { Link as LinkRoute } from 'react-router-dom'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { Loader } from 'core/components/Loader'
import moment from 'moment'
import { ProjectParentFilter } from 'core/components/table/filters/ProjectParentFilter'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
} from '@material-ui/core'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import ListIcon from '@material-ui/icons/List'

export const Collection = ({
  wrapWithPaper = true,
  showNewButton = true,
  ...rest
}) => {
  const profile = useSelector(state => state.profile)

  const pid = rest.match.params.id

  const [tabValue, setTabValue] = React.useState(
    localStorage.getItem('project_tab')
      ? parseInt(localStorage.getItem('project_tab'), 10)
      : 1
  )

  const columns = useMemo(
    () => [
      {
        header: translate('Title'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        sortable: false,
        sorterName: 'translations.title',
        // Cell: MainCell,
        width: '73%',
      },

      {
        ...operations(
          `translations.${process.env.REACT_APP_LOCALE}.title`,
          true,
          true,
          profile.isModerator ? true : false,
          true,
          true
        ),
        width: '15%',
      },
    ],
    [profile.isModerator]
  )

  const defaultFilters = pid
    ? {
        'exists[versionable]': false,
        'exists[parent]': true,
        pagination: true,
        'parent.uuid': pid,
        pageType: 'project',
      }
    : {
        'exists[versionable]': false,
        'exists[parent]': false,
        pagination: true,
        pageType: 'project',
      }

  const [orderableBlock, setblockOrderable] = useState(false)
  const blockOrderable = bul => setblockOrderable(bul)
  const customFilters = [
    {
      name: 'donothing',
      title: 'szukaj wszędzie',
      value: false,
      extraparams: { pid: pid, blockOrderable: blockOrderable },
      Filter: ProjectParentFilter,
    },
  ]

  const customReset = () => setblockOrderable(false)
  const WrapComponent = wrapWithPaper ? Paper : 'div'
  const collectionId = 'projects'

  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  useEffect(
    () => {
      setBreadcrumbs([])

      const controller = new AbortController()
      const { signal } = controller

      const endpoint = `/api/pages/breadcrumbs`

      if (pid && tabValue === 1) {
        setIsFetching(true)
        fetchDataHandleAuthError(
          `${endpoint}?bread=${pid}&type=project`,
          'GET',
          { signal },
          response => {
            setIsFetching(false)
            if (response['hydra:member']) {
              setBreadcrumbs(response['hydra:member'])
            }
          },
          error => {
            setIsFetching(false)
            if (error.response.title === 'AbortError') {
              return
            }
            notification(
              'error',
              error.response.detail ?? 'Error',
              error.response.title ?? null
            )
          }
        )
      }

      return () => controller.abort()
    },
    // eslint-disable-next-line
    [pid]
  )

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  // ------------------------------------------------------

  const tabHandleChange = (event, newValue) => {
    setTabValue(newValue)
    localStorage.setItem('project_tab', newValue)
  }

  const defaultFilters2 = {
    'exists[versionable]': false,
    'exists[parent]': false,
    pagination: false,
    pageType: 'project',
  }

  let isStatable = resource => {
    if (profile.isOpenform) {
      return true
    }
    if (resource.isStatable && profile.isModerator) {
      return true
    }
    return false
  }

  // -------------------------------------------------------

  return (
    <WrapComponent key={pid}>
      {tabValue === 1 && (
        <CollectionHeader
          buttonTitle={`Dodaj nowy ${
            breadcrumbs[breadcrumbs.length - 1]
              ? `do "${truncateString(
                  breadcrumbs[breadcrumbs.length - 1].title,
                  13
                )}"`
              : ''
          }`}
          buttonPath={
            pid
              ? routes().newParent.path.replace(':pid', pid)
              : routes().new.path
          }
          hideButton={!showNewButton}
        >
          {translate('Lista projektów')}
        </CollectionHeader>
      )}
      {tabValue === 2 && (
        <CollectionHeader
          buttonTitle={translate('Dodaj nowy')}
          buttonPath={routes().new.path}
          hideButton={!showNewButton}
        >
          {translate('Lista projektów')}
        </CollectionHeader>
      )}

      <div style={{ display: 'flex', width: '100%', justifyContent: 'right' }}>
        <Tabs value={tabValue} onChange={tabHandleChange}>
          <Tab icon={<AccountTreeIcon />} id="simple-tab-2" value={2} />
          <Tab icon={<ListIcon />} id="simple-tab-1" value={1} />
        </Tabs>
      </div>

      {isFetching ? (
        <Loader />
      ) : (
        tabValue === 1 && (
          <div>
            <Link component={LinkRoute} to={routes().index.path}>
              <HomeIcon color="primary" style={{ verticalAlign: 'middle' }} />
            </Link>
            {breadcrumbs.map(breadcrumb => {
              return (
                <Link
                  component={LinkRoute}
                  to={routes().indexParent.path.replace(':id', breadcrumb.uuid)}
                >
                  <KeyboardArrowRightIcon
                    size="small"
                    fontSize="small"
                    style={{ verticalAlign: 'middle' }}
                  />
                  <span style={{ verticalAlign: 'middle' }}>
                    {breadcrumb.title}
                  </span>
                </Link>
              )
            })}
          </div>
        )
      )}

      {/* widok drzewa */}
      <Accordion
        style={{ padding: 0, margin: 0, border: 0, boxShadow: 'none' }}
        expanded={tabValue === 2}
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: false,
          enter: false,
          exit: false,
          appear: false,
          timeout: {
            appear: 0,
            enter: 0,
            exit: 0,
          },
        }}
        hidden={tabValue !== 2}
        aria-labelledby={`simple-tab-${2}`}
      >
        <AccordionSummary style={{ display: 'none' }}></AccordionSummary>
        <AccordionDetails style={{ padding: 0, margin: 0, border: 'none' }}>
          <SchemableCollection
            component={CollectionTableTree}
            path={`paths.${schema.endpoint}.get`}
            endpoint={schema.endpoint}
            customResourceSchema={schema.resource}
            defaultFilters={defaultFilters2}
            defaultSorters={{ ord: 'desc' }}
            selectable={[publish.on(), publish.off()]}
            isRowSelectable={resource => isStatable(resource)}
            editPath={routes().edit.path}
            autoWidth={false}
            customFilters={[]}
            storeCollectionId={`${collectionId}-tree`}
          />
        </AccordionDetails>
      </Accordion>

      {/* widok listy */}
      <Accordion
        style={{ padding: 0, margin: 0, border: 0, boxShadow: 'none' }}
        expanded={tabValue === 1}
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: false,
          enter: false,
          exit: false,
          appear: false,
          timeout: {
            appear: 0,
            enter: 0,
            exit: 0,
          },
        }}
        hidden={tabValue !== 1}
        aria-labelledby={`simple-tab-${1}`}
      >
        <AccordionSummary style={{ display: 'none' }}></AccordionSummary>
        <AccordionDetails style={{ padding: 0, margin: 0, border: 'none' }}>
          <SchemableCollection
            component={CollectionTable}
            path={`paths.${schema.endpoint}.get`}
            endpoint={schema.endpoint}
            columns={columns}
            customResourceSchema={schema.resource}
            defaultFilters={defaultFilters}
            defaultSorters={{ ord: 'desc' }}
            selectable={[publish.on(), publish.off()]}
            isRowSelectable={resource =>
              profile.isOpenform || resource.isStatable
            }
            editPath={routes().edit.path}
            autoWidth={false}
            customFilters={customFilters}
            orderable={orderableBlock ? false : true}
            isRowLinkable={true}
            customReset={customReset}
            storeCollectionId={`${collectionId}-${pid}-${moment().format(
              'DD.MM.YYYY HH:mm:ss'
            )}`}
          />
        </AccordionDetails>
      </Accordion>
    </WrapComponent>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
