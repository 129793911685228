export const modifyBlockByPage = (blockTypes, resource, part) => {
  switch (part) {
    case 'part_for_column':
      let modifiedProperties1 = blockTypes.filter(
        elem =>
          elem.name === 'anchor' ||
          elem.name === 'text' ||
          elem.name === 'textWithVideo' ||
          elem.name === 'textWithImage' ||
          elem.name === 'packageFile' ||
          elem.name === 'packageLogotype'
      )
      return modifiedProperties1
    case 'part_for_full':
      let modifiedProperties2 = blockTypes.filter(
        elem =>
          elem.name === 'textNews' ||
          elem.name === 'happenBlock' ||
          elem.name === 'educationBlock' ||
          elem.name === 'sobiescianaBlock' ||
          elem.name === 'pasazBlock' ||
          elem.name === 'iartcollectionBlock' ||
          elem.name === 'packageGallery'
      )
      return modifiedProperties2
    default:
      return blockTypes
  }
}
