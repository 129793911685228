import {
  ViewListOutlined,
  PeopleOutlined,
  DescriptionOutlined,
  SettingsOutlined,
} from '@material-ui/icons'
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import { boolean } from 'core/_helpers/boolean'
import home from 'core/pages/Home/routes'
import admins from 'core/pages/Admins/routes'
import adminGroups from 'core/pages/AdminGroups/routes'
import loginEntries from 'core/pages/LoginEntries/routes'
import registryEntries from 'core/pages/RegistryEntries/routes'
import settings from 'core/pages/Settings/routes'
import profile from 'core/pages/Profile/routes'
import messenger from 'core/pages/Messenger/routes'
import pages from 'core/pages/Pages/routes'
import translatorEntries from 'core/pages/TranslatorEntries/routes'
import packageFiles from 'core/pages/PackageFiles/routes'
import packageFaqs from 'core/pages/PackageFaqs/routes'
import packageGalleries from 'core/pages/PackageGalleries/routes'
import packageLogotypes from 'core/pages/PackageLogotypes/routes'
import packagePricelist from 'core/pages/PackagePricelist/routes'
// import homeBannerItems from 'pages/HomeBannerItems/routes'
import homeSections from 'pages/HomeSections/routes'
import news from 'pages/News/routes'
import happen from 'pages/Happens/routes'
import exhibition from 'pages/Exhibitions/routes'
import personItems from 'pages/PersonItems/routes'
import tagEntries from 'pages/TagEntries/routes'
import crews from 'pages/Crews/routes'
import shortcut from 'pages/Shortcuts/routes'
import config from 'pages/Config/routes'
import configbip from 'pages/Configbip/routes'

import shippingCoutries from 'pages/ShippingCountries/routes'
import shippingAreas from 'pages/ShippingAreas/routes'
import shippingTypes from 'pages/ShippingTypes/routes'
import paymentTypes from 'pages/PaymentTypes/routes'
import orders from 'pages/Orders/routes'
import shopForm from 'pages/ShopForm/routes'

import discountCode from 'pages/DiscountCodes/routes'
import discountProducts from 'pages/DiscountProducts/routes'
import productCategories from 'pages/ProductCategories/routes'
import products from 'pages/Products/routes'
import productFilter from 'pages/ProductFilter/routes'

import eduHome from 'pages/EducationCategoryHome/routes'
import eduMuseum from 'pages/EducationCategoryMuseum/routes'
import eduHappensMuseum from 'pages/EducationMuseum/routes'
import eduHappensHome from 'pages/EducationHome/routes'
import happenCategory from 'pages/EducationCategoryHappen/routes'
import visitCategory from 'pages/EducationCategoryVisit/routes'
import places from 'pages/Places/routes'

import iart from 'pages/IartCollections/routes'

import pwaConfig from 'pages/PwaConfig/routes'
import pwaMenu from 'pages/PwaMenu/routes'
import mainMenu from 'pages/MainMenu/routes'

import projectCategory from 'pages/EducationCategoryProject/routes'
import projects from 'core/pages/Projects/routes'
import sobiesciana from 'pages/Sobiesciana/routes'
import pasaz from 'pages/Pasazs/routes'
import tagPasazEntries from 'pages/TagPasazEntry/routes'
import pasazTopics from 'pages/PasazTopic/routes'

import tstruct from 'pages/TagStructs/routes'
import sobiescianaCategory from 'pages/SobiescianaCategory/routes'
import sobiescianaFilter from 'pages/SobiescianaFilter/routes'

import bip from 'core/pages/Bip/routes'
import users from 'pages/Users/routes'
import formset from 'pages/Formset/routes'
import newsletterrecipients from 'pages/NewsletterRecipient/routes'
import newsletterGroup from 'pages/NewsletterGroup/routes'
import newsletter from 'pages/Newsletter/routes'
import residence from 'pages/Residence/routes'
import residenceGroup from 'pages/ResidenceGroup/routes'
import cookies from 'pages/CookieItem/routes'
import cookieIp from 'pages/CookieIp/routes'
import gigapixel from 'pages/Gigapixel/routes'
import whitelist from 'pages/Whitelist/routes'

import vat from 'pages/Vats/routes'
import orderEmail from 'pages/OrderEmail/routes'

const menu = () =>
  [
    home().index,
    homeSections().edit,
    pages().index,
    mainMenu().index,
    shortcut().index,
    {
      title: 'T_MENU_PACKAGES',
      icon: ViewListOutlined,
      items: [
        packageFiles().index,
        packageGalleries().index,
        packageLogotypes().index,
        packageFaqs().index,
        packagePricelist().index,
        formset().index,
        gigapixel().index,
      ],
    },

    {
      title: 'Rezydencja',
      icon: residence().index.icon,
      items: [
        residence().index,
        residenceGroup().index
      ],
    },

    news().index,

    {
      title: 'Wydarzenia',
      icon: AssignmentOutlinedIcon,
      items: [
        happenCategory().index,
        visitCategory().index,
        places().index,
        happen().index,
      ],
    },

    exhibition().index,

    {
      title: 'Education',
      icon: SchoolOutlinedIcon,
      items: [
        eduMuseum().index,
        eduHome().index,
        eduHappensMuseum().index,
        eduHappensHome().index
      ],
    },

    {
      title: 'Projekty',
      icon: BusinessCenterIcon,
      items: [
        projectCategory().index,
        projects().index
      ],
    },

    {
      title: 'Sobiesciana',
      icon: sobiesciana().index.icon,
      items: [
        sobiesciana().index,
        sobiescianaCategory().index,
        sobiescianaFilter().index,
        tstruct().index,
      ]
    },

    {
      title: 'Pasaż wiedzy',
      icon: pasaz().index.icon,
      items: [
        pasaz().index,
        pasazTopics().index,
        tagPasazEntries().index
      ]
    },

    iart().index,
    tagEntries().index,
    personItems().index,
    crews().index,
    bip().index,
    // homeBannerItems().index,
    {
      title: 'Products',
      icon: FolderOpenOutlinedIcon,
      items: [
        products().index,
        productCategories().index,
        productFilter().index,
        discountProducts().index,
        discountCode().index,
      ],
    },
    {
      title: 'Sklep',
      icon: ShoppingCartOutlinedIcon,
      items: [
        orders().index,
        users().index,
        paymentTypes().index,
        shippingTypes().index,
        shippingAreas().index,
        shippingCoutries().index,
        shopForm().index,
        vat().index,
        orderEmail().index,
      ],
    },
    {
      title: 'Newsletter',
      icon: EmailOutlinedIcon,
      items: [
        newsletterrecipients().index,
        newsletterGroup().index,
        newsletter().index
      ],
    },
    {
      title: 'Aplikacja',
      icon: PhoneAndroidOutlinedIcon,
      items: [
        pwaConfig().edit,
        pwaMenu().index,
      ],
    },

    {
      title: 'Administrators',
      icon: PeopleOutlined,
      items: [admins().index, adminGroups().index],
    },
    {
      title: 'T_MENU_REGISTRIES',
      icon: DescriptionOutlined,
      items: [registryEntries().index, loginEntries().index],
    },
    {
      title: 'T_MODULE_SETTINGS',
      icon: SettingsOutlined,
      items: [
        config().edit,
        configbip().edit,
        cookies().index,
        cookieIp().index,
        whitelist().index,
        settings().index,
        profile().index,
        {
          title: 'File manager',
          type: 'ofm',
          path: 'null',
          exact: false,
          icon: 'null'
        }
      ],
    },
    translatorEntries().index,
  ].concat(
    boolean(process.env.REACT_APP_MESSENGER_SHOW) ? [messenger().index] : []
  )

export default menu
