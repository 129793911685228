import React from 'react'
import { Form } from 'core/pages'
import { SidebarNotStatable } from 'core/components/resource/sidebar'
import schema from '_schema/newsletterGroup'
import routes from './routes'
import { useSelector } from 'react-redux'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const profile = useSelector(state => state.profile)

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
      modifyProperties={(properties, resource, schema) => {
        let modifiedProperties = JSON.parse(JSON.stringify(properties))
        if (profile.isOpenform) {
          return {
            ...modifiedProperties,
            idName: {
              type: 'string',
              description: {
                text: 'T_GENERAL_OPENFORM_PAGE_IDNAME',
                color: '#cc0000',
              },
              hint: 'idname grupy, jesli wpisany nie mozna skasować grupy'
            },
          }
        }

        return modifiedProperties
      }}
    />
  )
}
