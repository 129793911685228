import _block from 'pages/Newsletter/_block'
import photoSchema from 'core/_schema/photo'
import fileSchema from 'core/_schema/file'

const schema = {
  endpoint: '/api/newsletters',
  resource: {
    definition: 'Newsletter-newsletter.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Title',
            hint: 'widoczny tylko w PA, oraz jako tytuł w emaila',
          },
        },
      },

      // stat: {
      //   description: 'T_GENERAL_PUBLICATION',
      // },

      newsletterGroups: {
        type: 'multipleResource',
        endpoint: `/api/newsletter_groups?pagination=false`,
        titleAccessor: `title`,
        validate: ['required'],
      },

      restart: {
        hint:
          'jesli włączysz, zresetujesz ustawienia, bedziesz mógł odnowa wysłać ten newsletter',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/newsletter_blocks?pagination=false',
      definition: 'NewsletterBlock-newsletterBlock.read',
      types: _block(
        photoSchema.endpoint.single,
        '/api/newsletter_block_thumbs',
        fileSchema.endpoint.single
      ).types,
    },
  },
}

export default schema
