const schema = {
  endpoint: '/api/newsletter_recipients',
  resource: {
    definition: 'NewsletterRecipient-newsletterRecipient.read',
    properties: {
      email: {},
      lang: {},
      consentRodo: {},
      consentMarketing: {},
      stat: {
        label: 'Aktywny, potwierdzony z linka',
        hint: 'nieaktywni lub nie potwierdzone linkiem z maila osoby nie otrzymują newslettera'
      },
      createdAt: {
        label: 'Data i czas dodania',
        type: 'datetime',
        readonly: true,
        disabled: true,
      },
      newsletterGroups: {
        type: 'multipleResource',
        endpoint: `/api/newsletter_groups?pagination=false`,
        titleAccessor: `title`,
      },
    },
  },
}

export default schema
