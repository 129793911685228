import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { FirstPage, LastPage } from '@material-ui/icons'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  dropzone: {
    border: '2px dashed #D7DBDF',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '5px 20px',
    margin: 0,
    height: 60,
    textAlign: 'center',
    color: '#929292',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    '& svg': {
      marginTop: 0,
    },
  },
}))

export const ManualOrderPageDropzone = ({
  isFirst,
  isLast,
  previousPageRef = null,
  nextPageRef = null,
  hide = false,
  classes = {},
}) => {
  const defaultClasses = useStyles()

  return hide ? null : (
    <div
      className={clsx(defaultClasses.root, classes.root)}
      style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }}
    >
      {!isFirst && (
        <div
          className={clsx(defaultClasses.dropzone, classes.dropzone)}
          ref={previousPageRef}
        >
          <FirstPage />
          <span>
            {translate('T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_PREVIOUS')}
          </span>
        </div>
      )}
      {!isLast && (
        <div
          className={clsx(defaultClasses.dropzone, classes.dropzone)}
          ref={nextPageRef}
        >
          <span>{translate('T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_NEXT')}</span>
          <LastPage />
        </div>
      )}
    </div>
  )
}

ManualOrderPageDropzone.propTypes = {
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  previousPageRef: PropTypes.func,
  nextPageRef: PropTypes.func,
  hide: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    dropzone: PropTypes.string,
  }),
}
