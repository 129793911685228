import { boolean } from 'core/_helpers/boolean'
import home from 'core/pages/Home/routes'
import admins from 'core/pages/Admins/routes'
import adminGroups from 'core/pages/AdminGroups/routes'
import loginEntries from 'core/pages/LoginEntries/routes'
import registryEntries from 'core/pages/RegistryEntries/routes'
import settings from 'core/pages/Settings/routes'
import profile from 'core/pages/Profile/routes'
import messenger from 'core/pages/Messenger/routes'
import pages from 'core/pages/Pages/routes'
import translatorEntries from 'core/pages/TranslatorEntries/routes'
import packageFiles from 'core/pages/PackageFiles/routes'
import packageFaqs from 'core/pages/PackageFaqs/routes'
import packageGalleries from 'core/pages/PackageGalleries/routes'
import packageGalleryItems from 'core/pages/PackageGalleryItems/routes'
import packageLogotypes from 'core/pages/PackageLogotypes/routes'
import packageLogotypeItems from 'core/pages/PackageLogotypeItems/routes'
import packagePricelist from 'core/pages/PackagePricelist/routes'
import tagEntries from 'pages/TagEntries/routes'
import homeBannerItems from 'pages/HomeBannerItems/routes'
import personItems from 'pages/PersonItems/routes'
import config from 'pages/Config/routes'
import configbip from 'pages/Configbip/routes'
import crews from 'pages/Crews/routes'
import news from 'pages/News/routes'
import happens from 'pages/Happens/routes'
import exhibitions from 'pages/Exhibitions/routes'
import shortcut from 'pages/Shortcuts/routes'
import homeSections from 'pages/HomeSections/routes'

import shippingCoutries from 'pages/ShippingCountries/routes'
import shippingAreas from 'pages/ShippingAreas/routes'
import shippingTypes from 'pages/ShippingTypes/routes'
import paymentTypes from 'pages/PaymentTypes/routes'
import orders from 'pages/Orders/routes'
import discountCode from 'pages/DiscountCodes/routes'
import discountProducts from 'pages/DiscountProducts/routes'
import products from 'pages/Products/routes'
import productCategories from 'pages/ProductCategories/routes'
import productFilter from 'pages/ProductFilter/routes'

import eduHome from 'pages/EducationCategoryHome/routes'
import eduMuseum from 'pages/EducationCategoryMuseum/routes'
import eduHappensMuseum from 'pages/EducationMuseum/routes'
import eduHappensHome from 'pages/EducationHome/routes'
import happenCategory from 'pages/EducationCategoryHappen/routes'
import visitCategory from 'pages/EducationCategoryVisit/routes'
import places from 'pages/Places/routes'

import iart from 'pages/IartCollections/routes'
import pwaConfig from 'pages/PwaConfig/routes'
import pwaMenu from 'pages/PwaMenu/routes'

import projectCategory from 'pages/EducationCategoryProject/routes'
import projects from 'core/pages/Projects/routes'
import sobiesciana from 'pages/Sobiesciana/routes'
import pasaz from 'pages/Pasazs/routes'

import tstruct from 'pages/TagStructs/routes'
import sobiescianaCategory from 'pages/SobiescianaCategory/routes'
import sobiescianaFilter from 'pages/SobiescianaFilter/routes'
import bip from 'core/pages/Bip/routes'
import mainMenu from 'pages/MainMenu/routes'

import tagPasazEntries from 'pages/TagPasazEntry/routes'
import pasazTopics from 'pages/PasazTopic/routes'
import users from 'pages/Users/routes'

import formset from 'pages/Formset/routes'
import newsletterrecipients from 'pages/NewsletterRecipient/routes'
import newsletterGroup from 'pages/NewsletterGroup/routes'
import newsletter from 'pages/Newsletter/routes'
import residence from 'pages/Residence/routes'
import residenceGroup from 'pages/ResidenceGroup/routes'
import cookies from 'pages/CookieItem/routes'
import cookieIp from 'pages/CookieIp/routes'
import gigapixel from 'pages/Gigapixel/routes'
import whitelist from 'pages/Whitelist/routes'
import shopForm from 'pages/ShopForm/routes'
import vat from 'pages/Vats/routes'

import orderEmail from 'pages/OrderEmail/routes'

const routes = () =>
  [
    ...getArray(home()),
    ...getArray(homeSections()),
    ...getArray(pages()),
    ...getArray(productFilter()),
    ...getArray(formset()),
    ...getArray(newsletterrecipients()),
    ...getArray(newsletterGroup()),
    ...getArray(newsletter()),
    ...getArray(packageFiles()),
    ...getArray(packageFaqs()),
    ...getArray(packageGalleries()),
    ...getArray(packageGalleryItems()),
    ...getArray(packageLogotypes()),
    ...getArray(packageLogotypeItems()),
    ...getArray(packagePricelist()),
    ...getArray(homeBannerItems()),
    ...getArray(personItems()),
    ...getArray(cookieIp()),
    ...getArray(tagEntries()),
    ...getArray(tagPasazEntries()),
    ...getArray(crews()),
    ...getArray(gigapixel()),
    ...getArray(whitelist()),
    ...getArray(news()),
    ...getArray(cookies()),
    ...getArray(happens()),
    ...getArray(exhibitions()),
    ...getArray(eduHome()),
    ...getArray(eduMuseum()),
    ...getArray(eduHappensHome()),
    ...getArray(eduHappensMuseum()),
    ...getArray(happenCategory()),
    ...getArray(visitCategory()),
    ...getArray(projectCategory()),
    ...getArray(projects()),
    ...getArray(places()),
    ...getArray(residence()),
    ...getArray(residenceGroup()),
    ...getArray(shortcut()),
    ...getArray(products()),
    ...getArray(productCategories()),
    ...getArray(shippingTypes()),
    ...getArray(shippingCoutries()),
    ...getArray(shippingAreas()),
    ...getArray(shopForm()),
    ...getArray(paymentTypes()),
    ...getArray(discountCode()),
    ...getArray(discountProducts()),
    ...getArray(orders()),
    ...getArray(iart()),
    ...getArray(bip()),
    ...getArray(pwaConfig()),
    ...getArray(pwaMenu()),
    ...getArray(mainMenu()),
    ...getArray(sobiesciana()),
    ...getArray(tstruct()),
    ...getArray(sobiescianaCategory()),
    ...getArray(sobiescianaFilter()),
    ...getArray(pasaz()),
    ...getArray(pasazTopics()),
    ...getArray(users()),
    ...getArray(admins()),
    ...getArray(adminGroups()),
    ...getArray(loginEntries()),
    ...getArray(registryEntries()),
    ...getArray(translatorEntries()),
    ...getArray(config()),
    ...getArray(configbip()),
    ...getArray(settings()),
    ...getArray(profile()),
    ...getArray(vat()),
    ...getArray(orderEmail()),
  ].concat(
    boolean(process.env.REACT_APP_MESSENGER_SHOW) ? getArray(messenger()) : []
  )

const getArray = routes => Object.values(routes)

export default routes
