import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
// import { useSelector } from 'react-redux'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import { publish } from 'core/components/table/_mass'
// import { MainCell } from './table/cells/MainCell'
import schema from '_schema/newsletterRecipient'
import schemaNewsletterGrup from '_schema/newsletterGroup'
import routes from './routes'
import { ResourceFilter } from 'core/components/table/filters/ResourceFilter'
import { YesNoFilter } from 'core/components/table/filters/YesNoFilter'
import { exportselected } from 'core/pages/TranslatorEntries/table/exportselected'

export const Collection = () => {
  // const profile = useSelector(state => state.profile)
  const columns = useMemo(
    () => [
      {
        header: translate('Email'),
        accessor: `email`,
        filterName: 'email',
        filterable: true,
        // Cell: MainCell,
        width: '43%',
      },

      { ...operations(`email`, true, true, true, true, false), width: '15%' },
    ],
    []
  )

  const customFilters = [
    {
      name: 'newsletterGroups.uuid',
      title: 'Grupa odbiorców',
      value: null,
      extraparams: {
        endpoint: `${schemaNewsletterGrup.endpoint}?pagination=false`,
        accessor: `title`,
        nocategory: true,
      },
      Filter: ResourceFilter,
    },
    {
      name: 'stat',
      title: 'Aktywny,potwierdzony',
      value: null,
      Filter: YesNoFilter,
    },
  ]

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj odbiorcę')}
        buttonPath={routes().new.path}
      >
        {translate('Odbiorcy')}
        &nbsp;
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off(), exportselected.delete()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        customFilters={customFilters}
      />
    </Paper>
  )
}
