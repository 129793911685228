

const schema = {
  endpoint: '/api/newsletter_groups',
  resource: {
    definition: 'NewsletterGroup-newsletterGroup.read',
    properties: {
      title: {},
      stat: {}
    },
  },
}

export default schema
