import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  imgContainer: {},
  img: {
    maxHeight: 100,
    height: 'auto',
  },
})

export const ImgCell = ({
  resource,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath &&
        history.push(
          editPath.replace(':id', resource[process.env.REACT_APP_RESOURCE_ID])
        )
    }
  }

  const classes = useStyles()

  return (
    <div onClick={onClick} className={classes.image}>
      <div className={classes.imgContainer}>
        <img
          className={classes.img}
          alt="--"
          src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/sobiescianas/miniphoto/${resource.uuid}/get`}
        />
      </div>
    </div>
  )
}

ImgCell.propTypes = {
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
